// @generated by protobuf-ts 2.8.2 with parameter long_type_string
// @generated from protobuf file "proto/Request.proto" (syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "../google/protobuf/any";
/**
 * @generated from protobuf message Request
 */
export interface Request {
    /**
     * @generated from protobuf field: string event = 1;
     */
    event: string; // login,logout,subscribe,unsubscribe,ping,connect
    /**
     * @generated from protobuf field: google.protobuf.Any data = 2;
     */
    data?: Any;
}
/**
 * 登入
 *
 * @generated from protobuf message LoginReq
 */
export interface LoginReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * 订阅/取消订阅
 *
 * @generated from protobuf message SubscribeReq
 */
export interface SubscribeReq {
    /**
     * @generated from protobuf field: repeated SubscribeReq.Body subs = 1;
     */
    subs: SubscribeReq_Body[];
}
/**
 * @generated from protobuf message SubscribeReq.Body
 */
export interface SubscribeReq_Body {
    /**
     * @generated from protobuf field: string biz = 1;
     */
    biz: string; // 业务 spot/perpetual
    /**
     * @generated from protobuf field: string type = 2;
     */
    type: string; // 类型 depth/deal/asset
    /**
     * @generated from protobuf field: string base = 3;
     */
    base: string; // 交易币种 btc/ltc/eth
    /**
     * @generated from protobuf field: string quote = 4;
     */
    quote: string; // 计价币种 cny/usd/btc
    /**
     * @generated from protobuf field: string contractCode = 5;
     */
    contractCode: string; // 交易币种 p_btc-usdt
    /**
     * @generated from protobuf field: string granularity = 6;
     */
    granularity: string; // 时间周期 1min/ 5min 等
}
/**
 * 连接
 *
 * @generated from protobuf message ConnectReq
 */
export interface ConnectReq {
    /**
     * @generated from protobuf field: int32 businessId = 1;
     */
    businessId: number; // 商户id
    /**
     * @generated from protobuf field: string client = 2;
     */
    client: string; // 客户端类型 与http header的全局参数一致
    /**
     * @generated from protobuf field: string version = 3;
     */
    version: string; // 版本号
    /**
     * @generated from protobuf field: string token = 4;
     */
    token: string; // 用户token 未登录状态下不要传，避免无用解析
    /**
     * @generated from protobuf field: string deviceId = 5;
     */
    deviceId: string; // 设备id
}
// @generated message type with reflection information, may provide speed optimized methods
class Request$Type extends MessageType<Request> {
    constructor() {
        super("Request", [
            { no: 1, name: "event", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "message", T: () => Any }
        ]);
    }
    create(value?: PartialMessage<Request>): Request {
        const message = { event: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Request>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Request): Request {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string event */ 1:
                    message.event = reader.string();
                    break;
                case /* google.protobuf.Any data */ 2:
                    message.data = Any.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Request, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string event = 1; */
        if (message.event !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.event);
        /* google.protobuf.Any data = 2; */
        if (message.data)
            Any.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Request
 */
export const Request = new Request$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginReq$Type extends MessageType<LoginReq> {
    constructor() {
        super("LoginReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginReq>): LoginReq {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LoginReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginReq): LoginReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LoginReq
 */
export const LoginReq = new LoginReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribeReq$Type extends MessageType<SubscribeReq> {
    constructor() {
        super("SubscribeReq", [
            { no: 1, name: "subs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubscribeReq_Body }
        ]);
    }
    create(value?: PartialMessage<SubscribeReq>): SubscribeReq {
        const message = { subs: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscribeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscribeReq): SubscribeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SubscribeReq.Body subs */ 1:
                    message.subs.push(SubscribeReq_Body.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscribeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated SubscribeReq.Body subs = 1; */
        for (let i = 0; i < message.subs.length; i++)
            SubscribeReq_Body.internalBinaryWrite(message.subs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscribeReq
 */
export const SubscribeReq = new SubscribeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscribeReq_Body$Type extends MessageType<SubscribeReq_Body> {
    constructor() {
        super("SubscribeReq.Body", [
            { no: 1, name: "biz", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "base", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "quote", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "contractCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "granularity", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SubscribeReq_Body>): SubscribeReq_Body {
        const message = { biz: "", type: "", base: "", quote: "", contractCode: "", granularity: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SubscribeReq_Body>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubscribeReq_Body): SubscribeReq_Body {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string biz */ 1:
                    message.biz = reader.string();
                    break;
                case /* string type */ 2:
                    message.type = reader.string();
                    break;
                case /* string base */ 3:
                    message.base = reader.string();
                    break;
                case /* string quote */ 4:
                    message.quote = reader.string();
                    break;
                case /* string contractCode */ 5:
                    message.contractCode = reader.string();
                    break;
                case /* string granularity */ 6:
                    message.granularity = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubscribeReq_Body, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string biz = 1; */
        if (message.biz !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.biz);
        /* string type = 2; */
        if (message.type !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.type);
        /* string base = 3; */
        if (message.base !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.base);
        /* string quote = 4; */
        if (message.quote !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.quote);
        /* string contractCode = 5; */
        if (message.contractCode !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.contractCode);
        /* string granularity = 6; */
        if (message.granularity !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.granularity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubscribeReq.Body
 */
export const SubscribeReq_Body = new SubscribeReq_Body$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConnectReq$Type extends MessageType<ConnectReq> {
    constructor() {
        super("ConnectReq", [
            { no: 1, name: "businessId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "client", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ConnectReq>): ConnectReq {
        const message = { businessId: 0, client: "", version: "", token: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConnectReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConnectReq): ConnectReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 businessId */ 1:
                    message.businessId = reader.int32();
                    break;
                case /* string client */ 2:
                    message.client = reader.string();
                    break;
                case /* string version */ 3:
                    message.version = reader.string();
                    break;
                case /* string token */ 4:
                    message.token = reader.string();
                    break;
                case /* string deviceId */ 5:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConnectReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 businessId = 1; */
        if (message.businessId !== 0)
            writer.tag(1, WireType.Varint).int32(message.businessId);
        /* string client = 2; */
        if (message.client !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.client);
        /* string version = 3; */
        if (message.version !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.version);
        /* string token = 4; */
        if (message.token !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.token);
        /* string deviceId = 5; */
        if (message.deviceId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ConnectReq
 */
export const ConnectReq = new ConnectReq$Type();
