import create from 'zustand'
import produce from 'immer'
import { createTrackedSelector } from 'react-tracked'
import ws from '@/plugins/ws'
import { ISubscribeParams } from '@/plugins/ws/types'
import { WsBizEnum, WsTypesEnum } from '@/constants/ws'
import { MemberMemberPivacyType } from '@/constants/download'

let subscribed = false

function createOnWs(_, get) {
  return {
    onC2COrderStatusPush() {
      const state = get()
      state.getGuoqiTime()
    },
  }
}

type IStore = ReturnType<typeof getCardInfos>

function getCardInfos(set, get) {
  const state = {
    guoqiTime: 0,
    async getGuoqiTime() {
      const data = await (Date.now() + 1000 * 60)
      set(
        produce((draft: IStore) => {
          draft.guoqiTime = data
        })
      )
    },
    clearGuoqiTime() {
      set(
        produce((draft: IStore) => {
          draft.guoqiTime = 0
        })
      )
    },
    c2cOrderSubscribe() {
      if (subscribed) {
        return () => {}
      }
      const { onC2COrderStatusPush } = createOnWs(set, get)
      const subscribeParams: ISubscribeParams[] = [
        {
          subs: {
            biz: WsBizEnum.c2c,
            type: WsTypesEnum.c2corder,
          },
          callback: onC2COrderStatusPush,
        },
      ]
      subscribeParams.forEach(({ callback, ...params }) => {
        ws.subscribe({
          ...params,
          callback,
        })
      })
      subscribed = true
      return () => {
        subscribed = false
        subscribeParams.forEach(params => {
          ws.unsubscribe(params)
        })
      }
    },
    draftObj: {
      privacyPath: '',
      termsPath: '',
    },
    setProtocol(item) {
      set(
        produce((draft: IStore) => {
          item.forEach(rowkey => {
            if (rowkey?.homeColumnCd === MemberMemberPivacyType.privacy_policy) {
              draft.draftObj.privacyPath = rowkey?.webUrl || ''
            }
            if (rowkey?.homeColumnCd === MemberMemberPivacyType.terms_service) {
              draft.draftObj.termsPath = rowkey?.webUrl || ''
            }
          })
        })
      )
    },
  }
  return state
}

const baseCardInfo = create(getCardInfos)
const useCardInfo = createTrackedSelector(baseCardInfo)

export { useCardInfo, baseCardInfo }
